import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { getBuildIdQuery, IMegaMenuVisibilitySettings, ISettingsResponse } from '@ncg/data';
import { FeatureDetectionService, HeaderService, ScrollStatusService, SettingsService } from '@ncg/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-header',
    template: `
        <div class="header__backdrop" [ngClass]="{ 'header__backdrop--is-open': isMegaMenuOverlayVisible }"></div>
        <div class="header__wrapper" [style.height]="headerHeight + 'px'">
            <header
                #header
                class="header"
                [ngClass]="{ 'header--is-sticky': isFixed }"
                [style.transform]="hideNavBar ? 'translate3d(0, ' + -headerHeight + 'px, 0)' : ''"
            >
                <ncg-top-menu class="is-hidden-touch" *ngIf="hasTopMenu" [links]="settings?.topMenuLinks"></ncg-top-menu>
                <div class="header__container">
                    <div class="header__left">
                        <ncg-main-menu
                            (isMegaMenuVisibleSettings)="onMegaMenuChange($event)"
                            class="header__main-menu is-hidden-touch"
                        ></ncg-main-menu>
                        <div class="header__mobile-menu is-hidden-desktop">
                            <button
                                ncgMobileMenuTrigger
                                [logoName]="'#ford-logo'"
                                type="button"
                                class="button is-clean mobile-header-button"
                                aria-label="menu"
                                aria-expanded="false"
                            >
                                <svg-icon-sprite
                                    src="nav"
                                    [viewBox]="'0 0 30 30'"
                                    [width]="'30px'"
                                    [height]="'30px'"
                                    aria-hidden="true"
                                ></svg-icon-sprite>
                                <span>{{ 'header.menu' | translate }}</span>
                            </button>
                        </div>
                    </div>
                    <ng-container *ngIf="settings?.logoLink?.url && settings?.logoLink?.isExternal; else defaultTmpl">
                        <a id="logo" class="logo" aria-label="Ford logo" [href]="settings?.logoLink?.url" [attr.target]="settings?.logoLink?.target">
                            <ng-container *ngTemplateOutlet="logoSymbolTmpl"></ng-container>
                        </a>
                    </ng-container>
                    <ng-template #defaultTmpl>
                        <a id="logo" class="logo" aria-label="Ford logo" [routerLink]="['/']">
                            <ng-container *ngTemplateOutlet="logoSymbolTmpl"></ng-container>
                        </a>
                    </ng-template>
                    <div class="header__right">
                        <div
                            *ngIf="settings?.showModelOverviewLinkInMobileHeader && settings?.modelOverviewLink as modelOverviewLink"
                            class="is-hidden-desktop"
                        >
                            <ncg-mobile-header-link icon="car" [link]="modelOverviewLink"></ncg-mobile-header-link>
                        </div>
                        <ncg-meta-menu></ncg-meta-menu>
                        <div *ngIf="settings?.featureToggles?.isSearchEnabled && settings?.showSearchInMobileHeader" class="is-hidden-desktop">
                            <ncg-mobile-header-search></ncg-mobile-header-search>
                        </div>
                    </div>
                </div>
            </header>
        </div>
        <ng-template #logoSymbolTmpl>
            <span class="is-hidden-touch">
                <svg-icon-sprite
                    [src]="'assets/images/sprite.svg' + buildIdQuery + '#ford-logo'"
                    [viewBox]="'0 0 92 52'"
                    [attribute]="[['aria-labelledby', 'logo']]"
                ></svg-icon-sprite>
            </span>
            <span class="is-hidden-desktop">
                <svg-icon-sprite
                    [src]="'assets/images/sprite.svg' + buildIdQuery + '#ford-logo'"
                    [viewBox]="'0 0 92 52'"
                    [attribute]="[['aria-labelledby', 'logo']]"
                ></svg-icon-sprite>
            </span>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly HAS_TOP_MENU_KEY = makeStateKey<boolean>('hasTopMenu');

    @ViewChild('header') private readonly headerElement: ElementRef;

    settings?: ISettingsResponse;
    isMegaMenuOverlayVisible = false;
    isFixed: boolean;
    previousPosition: number;
    hideNavBar = false;
    headerHeight: number;
    currentPosition: number;
    buildIdQuery = getBuildIdQuery();
    hasTopMenu: boolean;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly transferState: TransferState,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        private readonly featureDetection: FeatureDetectionService,
        private readonly settingsService: SettingsService
    ) {}

    ngOnInit() {
        if (this.transferState.hasKey(this.HAS_TOP_MENU_KEY)) {
            this.hasTopMenu = this.transferState.get(this.HAS_TOP_MENU_KEY, false);
            this.cd.markForCheck();
        }

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.settings = settings;
                this.hasTopMenu = settings.hasTopMenu;

                if (this.featureDetection.isServer()) {
                    this.transferState.set(this.HAS_TOP_MENU_KEY, this.hasTopMenu);
                }

                this.setInitialValues();
            });

        this.scrollStatusService
            .getScrollPosition()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((position: number) => {
                this.currentPosition = position;
                this.handleHeaderPosition();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialValues() {
        if (this.featureDetection.isBrowser() && this.headerElement) {
            this.headerHeight = this.headerElement.nativeElement.offsetHeight;
            this.previousPosition = this.headerHeight;
            this.headerService.updateModelPageHeader(this.headerHeight);
        }
        this.cd.markForCheck();
    }

    handleHeaderPosition() {
        if (this.currentPosition > this.headerHeight) {
            if (this.currentPosition > this.previousPosition) {
                this.hideNavBar = true;
                this.previousPosition = this.currentPosition - 1;
            } else {
                this.isFixed = true;
                this.hideNavBar = false;
                this.previousPosition = this.currentPosition;
            }
        } else if (this.currentPosition === 0) {
            this.isFixed = false;
            this.hideNavBar = false;
            this.previousPosition = this.headerHeight;
        }

        this.cd.markForCheck();
    }

    onMegaMenuChange(settings: IMegaMenuVisibilitySettings) {
        this.isMegaMenuOverlayVisible = settings.isVisible;
        this.cd.detectChanges();
    }
}
